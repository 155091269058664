<template>
  <ul class="menu-nav">
  </ul>
</template>

<script>
import { mapGetters } from "vuex";
import EventBus from "@/core/services/store/event-bus";
import Swal from "sweetalert2";
import axios from "axios";
import $ from 'jquery';

export default {
  name: "KTMenu",
  data() {
    return {
      
    };
  },
  methods: {
  },
  computed: {
    
  },
  watch: {
  },
  mounted() {
  }
};
</script>
